"use client";
import { useEffect } from "react";
import { Button } from "@/lib/ui";

export default function AdminError({
  error,
  reset,
}: {
  error: Error;
  reset: () => void;
}) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error);
  }, [error]);

  return (
    <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <h1 className="mt-4 text-3xl font-bold capitalize tracking-tight text-gray-900 sm:text-5xl">
          {error.message}
        </h1>
        <p className="mt-6 text-base leading-7 text-gray-600">
          Sorry, something went wrong!
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Button variant="solid" buttonColor="primary" onClick={() => reset()}>
            Try again
          </Button>
          <Button asChild variant="ghost">
            <a href="#" className="text-sm font-semibold text-gray-900">
              Contact support <span aria-hidden="true">&rarr;</span>
            </a>
          </Button>
        </div>
      </div>
    </main>
  );
}
